import React from 'react';
import './App.css';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {isMobile, BrowserView} from 'react-device-detect';

class App extends React.Component {
    constructor() {
        super()
        this.state = {
            currentState: 'main',
            value: 0,
            infos: [
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/captainamericathefirstavenger.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Captain
                            America: The first Avenger
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>1943 - 1945
                        </div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Steve Rogers, ein abgewiesener Militärsoldat,
                                verwandelt sich nach der Einnahme einer Dosis eines "Supersoldaten-Serums" in Captain
                                America. Aber Captain America zu sein, hat seinen Preis, denn er versucht, einen
                                Kriegshetzer und eine terroristische Organisation auszuschalten.
                            </div>
                        </BrowserView>
                    </div>
                ), (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/captainmarvel.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Captain
                            Marvel
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>1995</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Carol Danvers wird zu einer der mächtigsten
                                Heldinnen des Universums, als die Erde in einen galaktischen Krieg zwischen zwei
                                außerirdischen Rassen verwickelt wird.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/ironman.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Iron Man
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2010</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Nachdem er in einer afghanischen Höhle gefangen
                                gehalten wurde, entwirft der milliardenschwere Ingenieur Tony Stark eine einzigartige
                                bewaffnete Rüstung zur Bekämpfung des Bösen.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/ironman2.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Iron Man 2
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2011</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Da sich die Welt nun seiner Identität als Iron
                                Man bewusst ist, muss Tony Stark sowohl mit seiner sich verschlechternden Gesundheit als
                                auch mit einem rachsüchtigen Verrückten mit Verbindungen zum Vermächtnis seines Vaters
                                fertig werden.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/theincrediblehulk.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Der
                            unglaubliche Hulk
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2011</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Bruce Banner, ein Wissenschaftler auf der
                                Flucht vor der US-Regierung, muss ein Heilmittel für das Monster finden, in das er sich
                                verwandelt, wenn er die Beherrschung verliert.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/thor.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Thor</div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2011</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Der mächtige, aber arrogante Gott Thor wird aus
                                Asgard vertrieben, um unter den Menschen in Midgard (Erde) zu leben, wo er bald zu einem
                                ihrer besten Verteidiger wird.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/theavengers.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Marvel's The
                            Avengers
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2012</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Die mächtigsten Helden der Erde müssen
                                zusammenkommen und lernen, als Team zu kämpfen, wenn sie den schelmischen Loki und seine
                                außerirdische Armee daran hindern wollen, die Menschheit zu versklaven.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/ironman3.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Iron Man 3
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2012</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Als Tony Starks Welt von einem gewaltigen
                                Terroristen namens Mandarin auseinandergerissen wird, beginnt er eine Odyssee des
                                Wiederaufbaus und der Vergeltung.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/thorthedarkworld.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Thor - The
                            Dark Kingdom
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2013</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Wenn die Dunkelelfen versuchen, das Universum
                                in die Dunkelheit zu stürzen, muss sich Thor auf eine gefährliche und persönliche Reise
                                begeben, die ihn mit der Ärztin Jane Foster wieder vereinen wird.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/captainamericathewintersoldier.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>The Return of
                            the First Avenger
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2014</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Während Steve Rogers darum kämpft, seine Rolle
                                in der modernen Welt zu übernehmen, schließt er sich mit einem Kollegen von Avenger und
                                S.H.I.E.L.D., Black Widow, zusammen, um eine neue Bedrohung aus der Geschichte zu
                                bekämpfen: einen Attentäter, der als Wintersoldat bekannt ist.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/guardiansofthegalaxy.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Guardians of
                            the Galaxy
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2014</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Eine Gruppe von intergalaktischen Kriminellen
                                muss an einem Strang ziehen, um einen fanatischen Krieger mit Plänen zur Säuberung des
                                Universums zu stoppen.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/guardiansofthegalaxyvol2.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Guardians of
                            the Galaxy Vol. 2
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2014</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Die Wächter haben Mühe, als Team
                                zusammenzuhalten, während sie sich mit ihren persönlichen Familienangelegenheiten
                                befassen, insbesondere mit der Begegnung von Star-Lord mit seinem Vater, dem ehrgeizigen
                                himmlischen Wesen Ego.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/avengersageofultron.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Avengers: Age
                            of Ultron
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2015</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Als Tony Stark und Bruce Banner versuchen, ein
                                schlummerndes Friedenssicherungsprogramm namens Ultron in Gang zu setzen, gehen die
                                Dinge schrecklich schief, und es liegt an den mächtigsten Helden der Erde, den
                                schurkischen Ultron daran zu hindern, seinen schrecklichen Plan in die Tat umzusetzen.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/antman.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Ant-Man</div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2015</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Bewaffnet mit einem Superanzug mit der
                                erstaunlichen Fähigkeit, in der Größe zu schrumpfen, aber in der Stärke zu wachsen, muss
                                der Fassadenkletterer Scott Lang seinen inneren Helden umarmen und seinem Mentor, Dr.
                                Hank Pym, helfen, einen Raubüberfall zu planen und durchzuführen, der die Welt retten
                                wird.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/captainamericacivilwar.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>The First
                            Avenger: Civil War
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2016</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Die politische Verwicklung in die
                                Angelegenheiten der Avengers führt zu einer Kluft zwischen Captain America und Iron Man.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/spidermanhomecoming.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Spider-Man:
                            Homecoming
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2016</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Peter Parker balanciert sein Leben als
                                gewöhnlicher Highschool-Schüler in Queens mit seinem Superhelden-Alter-Ego Spider-Man
                                aus und befindet sich auf der Spur einer neuen Bedrohung, die sich am Himmel von New
                                York City herumtreibt.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/doctorstrange.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Doctor
                            Strange
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2016 - 2017
                        </div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Auf einer Reise der körperlichen und
                                spirituellen Heilung wird ein brillanter Neurochirurg in die Welt der mystischen Künste
                                gezogen.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/blackpanther.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Black
                            Panther
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2017</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> T'Challa, Erbe des verborgenen, aber
                                fortgeschrittenen Königreichs Wakanda, muss vorwärts treten, um sein Volk in eine neue
                                Zukunft zu führen, und muss sich einem Herausforderer aus der Vergangenheit seines
                                Landes stellen.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/thorragnarok.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Thor: Tag der
                            Entscheidung
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2017 - 2018
                        </div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Gefangen auf dem Planeten Sakaar muss Thor
                                gegen die Zeit rennen, um nach Asgard zurückzukehren und Ragnarök, die Zerstörung seiner
                                Welt, durch die Hand der mächtigen und skrupellosen Schurkin Hela aufzuhalten.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/antmanandthewasp.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Ant-Man and
                            the Wasp
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2018</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Während Scott Lang ausbalanciert, sowohl ein
                                Superheld als auch ein Vater zu sein, präsentieren Hope van Dyne und Dr. Hank Pym eine
                                dringende neue Mission, bei der der Ameisenmann an der Seite von The Wasp kämpft, um
                                Geheimnisse aus ihrer Vergangenheit aufzudecken.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/avengersinfinitywar.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Avengers:
                            Infinity War
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2017 - 2018
                        </div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Die Rächer und ihre Verbündeten müssen bereit
                                sein, alles zu opfern, um den mächtigen Thanos zu besiegen, bevor sein Blitz der
                                Verwüstung und des Ruins dem Universum ein Ende bereitet.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/avengersendgame.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Avengers:
                            Endgame
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2018 - 2023
                        </div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Nach den verheerenden Ereignissen von Avengers:
                                Infinity War (2018) liegt das Universum in Trümmern. Mit Hilfe der verbliebenen
                                Verbündeten versammeln sich die Avengers erneut, um Thanos' Handlungen umzukehren und
                                das Gleichgewicht im Universum wiederherzustellen.
                            </div>
                        </BrowserView>
                    </div>
                ),
                (
                    <div className={isMobile ? "App-movie-info-mobile" : "App-movie-info"}
                         style={{backgroundImage: "url(movies/images/spidermanfarfromhome.jpg)"}}>
                        <div className={isMobile ? "App-movie-info-title-mobile" : "App-movie-info-title"}>Spider-Man:
                            Far From Home
                        </div>
                        <div className={isMobile ? "App-movie-info-year-mobile" : "App-movie-info-year"}>2024</div>
                        <BrowserView>
                            <div className="App-movie-info-description"> Nach den Ereignissen von Avengers: Endgame
                                (2019) muss Spider-Man sich in einer Welt, die sich für immer verändert hat, neuen
                                Bedrohungen stellen.
                            </div>
                        </BrowserView>
                    </div>
                ),
            ],
            images: [
                (<img src="movies/title/captainamericathefirstavenger.png" alt="" width="100%"/>),
                (<img src="movies/title/captainmarvel.png" alt="" width="100%"/>),
                (<img src="movies/title/ironman.png" alt="" width="100%"/>),
                (<img src="movies/title/ironman2.png" alt="" width="100%"/>),
                (<img src="movies/title/theincrediblehulk.png" alt="" width="100%"/>),
                (<img src="movies/title/thor.png" alt="" width="100%"/>),
                (<img src="movies/title/theavengers.png" alt="" width="100%"/>),
                (<img src="movies/title/ironman3.png" alt="" width="100%"/>),
                (<img src="movies/title/thorthedarkworld.png" alt="" width="100%"/>),
                (<img src="movies/title/captainamericathewintersoldier.png" alt="" width="100%"/>),
                (<img src="movies/title/guardiansofthegalaxy.png" alt="" width="100%"/>),
                (<img src="movies/title/guardiansofthegalaxyvol2.png" alt="" width="100%"/>),
                (<img src="movies/title/avengersageofultron.png" alt="" width="100%"/>),
                (<img src="movies/title/antman.png" alt="" width="100%"/>),
                (<img src="movies/title/captainamericacivilwar.png" alt="" width="100%"/>),
                (<img src="movies/title/spidermanhomecoming.png" alt="" width="100%"/>),
                (<img src="movies/title/doctorstrange.png" alt="" width="100%"/>),
                (<img src="movies/title/blackpanther.png" alt="" width="100%"/>),
                (<img src="movies/title/thorragnarok.png" alt="" width="100%"/>),
                (<img src="movies/title/antmanandthewasp.png" alt="" width="100%"/>),
                (<img src="movies/title/avengersinfinitywar.png" alt="" width="100%"/>),
                (<img src="movies/title/avengersendgame.png" alt="" width="100%"/>),
                (<img src="movies/title/spidermanfarfromhome.png" alt="" width="100%"/>),
            ],
        };
        this.onchange = this.onchange.bind(this);
        this.imprint = this.imprint.bind(this);
        this.faq = this.faq.bind(this);
        this.order = this.order.bind(this);
        this.main = this.main.bind(this);
        library.add(fab, far, fas);
    }

    onchange(value) {
        this.setState({value});
    }

    imprint() {
        this.setState({currentState: 'imprint'})
    }

    faq() {
        this.setState({currentState: 'faq'})
    }

    order() {
        this.setState({currentState: 'order'})
    }

    main() {
        this.setState({currentState: 'main'})
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/69/Marvel_Cinematic_Universe_Logo.png"
                         height="100%" alt="" onClick={this.main}/>
                </header>
                {this.state.currentState === 'main' ?
                    <main className="App-main">
                        <Carousel
                            value={this.state.value}
                            slides={this.state.infos}
                            draggable={false}
                            onChange={this.onchange}
                            arrowLeft={isMobile ? " " :
                                <FontAwesomeIcon className="arrow" icon={['fas', 'arrow-alt-circle-left']}/>}
                            arrowLeftDisabled={isMobile ? " " : <FontAwesomeIcon className="arrow" color="gray"
                                                                                 icon={['far', 'arrow-alt-circle-left']}/>}
                            arrowRight={isMobile ? " " :
                                <FontAwesomeIcon className="arrow" icon={['fas', 'arrow-alt-circle-right']}/>}
                            arrowRightDisabled={isMobile ? " " : <FontAwesomeIcon className="arrow" color="gray"
                                                                                  icon={['far', 'arrow-alt-circle-right']}/>}
                            addArrowClickHandler
                        />
                        <Carousel
                            value={this.state.value}
                            slidesPerPage={isMobile ? 4 : 9}
                            slides={this.state.images}
                            draggable={true}
                            centered
                            offset={isMobile ? 25 : 50}
                            clickToChange
                            onChange={this.onchange}
                        />
                    </main>
                    : this.state.currentState === 'imprint' ?
                        <main className="App-main">
                            <div className={isMobile ? "App-main-text-mobile" : "App-main-text"}>
                                <div className="App-main-text-header">
                                    <h2>Impressum</h2>
                                    <FontAwesomeIcon
                                        className={isMobile ? "App-main-text-close-mobile" : "App-main-text-close"}
                                        icon={['fas', 'times']} onClick={this.main}/>
                                </div>
                                <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
                                <p>Micha Preu&szlig;er<br/>
                                    Frankfurter Stra&szlig;e 99<br/>
                                    34121 Kassel</p>

                                <h3>Kontakt</h3>
                                <p>E-Mail: mcu-timeline@eskek.de</p>

                                <h3>Wichtige Informationen</h3>
                                <p>Diese Website steht in keiner Verbindung zu Disney,
                                    Marvel oder einem anderen Unternehmen und wird auch nicht direkt oder indirekt von
                                    diesen unterstützt. Es handelt sich hier lediglich um ein Fanprojekt.</p>
                            </div>
                        </main>
                            : this.state.currentState === 'order' ?
                                <main className="App-main">
                                    <div className={isMobile ? "App-main-text-mobile" : "App-main-text"}>
                                        <div className="App-main-text-header">
                                            <h2>Reihenfolge</h2>
                                            <FontAwesomeIcon
                                                className={isMobile ? "App-main-text-close-mobile" : "App-main-text-close"}
                                                icon={['fas', 'times']} onClick={this.main}/>
                                        </div>
                                        <div className='App-main-text-disclaimer'>
                                        <h3>Bitte erst lesen</h3>
                                        <p>
                                            Diese Seite enthält unfassbar große Spoiler. Bitte lest sie nur, wenn ihr entweder alle Filme kennt oder keinen Spaß beim Gucken haben möchtet.<br/>
                                            Die Reihenfolge wurde von uns als sinnvoll erachtet, kann allerdings natürlich auch von jedem anders gesehen werden.<br/>
                                            Wenn ihr unsere Argumente durchgelesen habt und uns trotzdem widersprechen wollte, könnt ihr uns gerne eine Mail an mcu-timeline@eskek.de schicken.
                                        </p>
                                        </div>
                                        <h3>Captain America: The first Avenger </h3>
                                        <p>
                                            Dieser Film ist definitv der erste des MCU. Oder? Tatsächtlich gibt es auch hier schon Diskussionsbedarf, denn der Hauptfilm spielt zwar von 1943-1945, allerdings reist man für die Eröffnungs- und Schlusszene ins Jahr 2012. Außerdem spielt Thors Eröffnungsszene 1000 Jahre vor dessen Verbannung. Wir merken also schon beim offensichtlichsten Film, dass die Einteilung gar nicht so einfach ist.
                                        </p>
                                        <h3>Captain Marvel</h3>
                                        <p>
                                            Im Jahr 1995 lernt man einige neue Charaktere kennen. Agent Coulson, Nick Fury und natürlich auch Captain Marvel. Wenn man von der Post-Credit Scene absieht, ist das auch richtig so. Die spielt allerdings am Anfang von Endgame und sorgt für Verwirrung bei denjenigen, die das MCU noch nicht kennen.
                                        </p>
                                        <h3>Iron Man</h3>
                                        <p>
                                            2008 fing das MCU mit diesem Film an, der 2010 spielt, was man daher weiß, dass die Handlung von Iron Man 2 sechs Monate nach seinem Vorgänger stattfindet.
                                        </p>
                                        <h3>Iron Man 2</h3>
                                        <p>
                                            Bei den nächsten drei Filmen streiten sich die Zeitleisten sehr über die korrekte Reihenfolge, da sie alle zeitgleich im Jahr 2011 spielen. Iron Man 2 spielt definitv vor Thor, da Phil Coulson am Ende des Films sagt, dass er nach New Mexico muss, wo der Hammer gefunden wurde. In der Abschlussszene von Iron Man 2, wird Tony als Berater bei S.H.I.E.L.D eingestellt, was die Reihenfolge mit Hulk erklärt.
                                        </p>
                                        <h3>Der unglaubliche Hulk</h3>
                                        <p>
                                            Tony fragt General Ross am Ende des Films, ob Abomination ein Avenger werden soll. Da Ross und Stark sich nicht leiden können, kommt der Deal nicht zustande. Das war von Coulson so geplant (im One Shot Der Berater). Tony war also schon Berater bei S.H.I.E.L.D, weshalb zumindest diese Szene nach Iron Man 2 spielen muss.
                                        </p>
                                        <h3>Thor</h3>
                                        <p>
                                            Zu guter letzt spielt im Jahr 2011 noch Thor. Die Reihenfolge von Thor und Hulk hätte man vermutlich auch gut tauschen können, allerdings kommen sehr viele wichtige Charaktere aus Thor im ersten Avengers mit, sodass es leichter ist, mitzukommen, wenn man die beiden Filme nacheinander schaut.
                                        </p>
                                        <h3>Marvel's The Avengers</h3>
                                        <p>
                                            Dieser Film ist eindeutig. Keine Flashbacks, keine Zeitreisen, keine springenden Schlussszenen. Dieser Film spielt im Jahr 2012.
                                        </p>
                                        <h3>Iron Man 3</h3>
                                        <p>
                                            Direkt nach den Ereignissen in NewYork 2012 spielt Iron Man 3 noch im selben Jahr. Aber auch hier kann man die Eröffnungsszene schon vor dem ersten Iron Man Film schauen, da diese zum Jahrtausendwechsel in Bern spielt. Dort lernen wir Ho Yinsen kennen, der später in Iron Man 1 Tonys Leben in der Höhle rettet.
                                        </p>
                                        <h3>Thor: The dark Kingdom</h3>
                                        <p>
                                            2013 gibt es nur einen Film, was die Einteilung sehr einfach macht.
                                        </p>
                                        <h3>The Return of the First Avenger</h3>
                                        <p>
                                            Die einen legen den zweiten Teil dieser Reihe hinter die Geschichte der Guardians of the Galaxy, die anderen davor. Fakt ist: beides spielt 2014 und da beide Handlungen erstmal nichts miteinander zu tun haben, bleibt es euch überlassen.
                                        </p>
                                        <h3>Guardians of the Galaxy</h3>
                                        <p>
                                            Die Eröffnungsszene zeigt den Tod von Peters Mutter 1988, der eigentliche Film spielt allerdings 26 Jahre später.
                                        </p>
                                        <h3>Guardians of the Galaxy Vol. 2</h3>
                                        <p>
                                            Egal ob man den zweiten Teil von Captain America jetzt nach vorne oder nach hinten geschoben hat. Guardians of the Galaxy 1 und 2 sollte man zusammen schauen. Zwei Monate liegen nur dazwischen und nun darf Peter Jason Quill seinen Vater kennenlernen.
                                        </p>
                                        <h3>Avengers: Age of Ultron</h3>
                                        <p>
                                            Im Jahr 2015 bringen wir eine Stadt zum Fliegen. In diesem Film wird außerdem Sam Wilson ein neuer Avenger.
                                        </p>
                                        <h3>Ant-Man</h3>
                                        <p>
                                            Die Information aus Age of Ultron ist deshalb so wichtig, weil sich Scott Lang und Sam Wilson in Ant-Man kennenlernen. Falcon ist hier nämlich schon ein Avenger.
                                        </p>
                                        <h3>The First Avenger: Civil War</h3>
                                        <p>
                                            In Deutschland gibt es einen Kampf zwischen den Avengers und.. den Avengers. Die Helden streiten sich, trennen sich und prügeln sich.
                                        </p>
                                        <h3>Spider-Man: Homecoming</h3>
                                        <p>
                                            Nach den Geschehnissen in Deutschland möchte Peter Benjamin Parker endlich mehr sein und ein "richtiger" Avenger werden.
                                        </p>
                                        <h3>Doctor Strange</h3>
                                        <p>
                                            In Civil War wurde Rhodes so stark verletzt, dass er nicht mehr richtig laufen konnte. Dr. Strange bekommt am Anfang seine Akte, um ihn zu operieren. Während er sie liest, achtet er zu wenig auf der Straße und die Geschichte nahm seinen Lauf.
                                        </p>
                                        <h3>Black Panther</h3>
                                        <p>
                                            Dieser Film spielt kurz nach dem Tod von T'Challas Vater in Civil War. Die Reihenfolge von Spider-Man, Dr. Strange und Black Panther ist auch sehr schwierig zu bestimmen.
                                        </p>
                                        <h3>Thor: Tag der Entscheidung</h3>
                                        <p>
                                            Thor und Loki kommen mal wieder auf die Erde. Dabei lernen sie auch Dr. Steven Strange kennen, was heißt, dass der Film auf jeden Fall nach Doctor Strange spielt. Außerdem ist die Zertörung Argards das Ereignis, was unmittelbar vor Infinity war geschieht.
                                        </p>
                                        <h3>Ant-Man and the Wasp</h3>
                                        <p>
                                            Obwohl zwischen Ragnarök und Infinity War eigentlich keine Zeit vergeht, muss Ant-Man hier mit rein. Eigentlich spielt der Film während Infinity War, da sich Hank Pym und dessen Frau und Tochter am Ende auflösen. Trotzdem empfehlen wir Endgame direkt im Anschluss an Infinity War zu schauen.
                                        </p>
                                        <h3>Avengers: Infinity War</h3>
                                        <p>
                                            Die Geschichte spielt, wie bereits angekündigt, direkt nach Thor 3 und endet mit einem epischen Kampf, den unsere Helden verlieren. Doch zum Glück hat Nick Fury uns am Ende noch einen Funken Huffnung gegeben, indem er auf seinen alten Pager gedrückt hat, den er 1995 bekommen hat.
                                        </p>
                                        <h3>Avengers: Endgame</h3>
                                        <p>
                                            Dieser Film kann überall eingeordnet werden, da Zeitreise anscheinend doch möglich ist. Aber wenn man diesen Punkt missachtet, spielt er eindeutig nach Infinity War.
                                        </p>
                                        <h3>Spider-Man: Far from Home</h3>
                                        <p>
                                            Nach Endgame geht alles wieder seinen gewohnten Gang und Peter ist wieder "Die freundliche Spinne aus der Nachbarschaft"
                                        </p>
                                    </div>
                                </main>
                        : ""}
                <footer className={isMobile ? "App-footer-mobile" : "App-footer"}>
                    <div>
                        <ul>
                            <li onClick={this.imprint}>
                                Impressum
                            </li>
                            <li onClick={this.order}>
                                Reihenfolge
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;

